// import { Link } from "react-router-dom"
import { Link } from "react-router-dom"
import Sections from "../Sections"
const Coach = () => {
    return (
        <Sections classes="conDark">
        <div className="row aic coach">
        <h2>HOW CAN I HELP YOU?</h2>
            <div className="col-7 col-md-6 col-sm-12 ">
              
        <Link className="link" to="/"><i className="i-e-gear"/> GROUP (TEAM) COACHING FOR ORGANIZATIONS</Link>
        <Link className="link" to="/"><i className="i-job"/> CAREER COACHING FOR INDIVIDUALS </Link>
        <Link className="link" to="/"><i className="i-flower"/> LIFE COACHING FOR INDIVIDUALS </Link>
            </div>
            <div className="col-5 col-md-6 col-sm-12  text-right">
                <img src={process.env.PUBLIC_URL + "./assets/img/home-page/coach.jpg"} alt="shubham agarwal" />
            </div>
        </div>

    </Sections>
    )
}

export default Coach
