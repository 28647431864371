import { Link } from "react-router-dom"
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"

const You = () => {
    return (
        <>
            <PageBanner pagename="You" />

            <Sections classes="qoute purquote2">
                <div className="quotes">It is better to live in your own destiny imperfectly than to live an imitation of somebody else's life with perfection.</div><p className="text-center"><b> - The Bhagavad Gita</b></p>
            </Sections>
            {/* section */}
            <Sections classes="conLight conGreenish WhyMe">
                <div className="flex aic">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./assets/img/you/myPick21.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl"> <p className="f18">You are way more resourceful than you believe right now!</p>
                        <h2>DOES THIS SOUND LIKE YOU?</h2>
                        <p>Driven but Directionless.</p>
                        <p>Aspirational but Confused.</p>
                        <p>Resourceful but Constrained.</p>
                        <p>Ingenious but Anxious.</p>
                        <p>Worthy but Doubtful.</p>
                        <p>Ambitious but Exhausted.</p>
                        <p>Whole but Lost.</p>
                        <p>Capable but Unfulfilled.</p>
                    </div>
                </div>

            </Sections>
            {/* section */}
            <Sections classes="qoute purquote2">
                <div className="quotes">You can't be anything you want but you can be everything you are</div><p className="text-center"><b> - Jay Shetty</b></p>
            </Sections>

            <Sections classes=" WhyMe watermark youare">

                <div className="flex aic">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./assets/img/you/youare.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl">
                        <h2>YOU ARE:</h2>
                        <ul className="list">
                            <li>Passionate, Committed, Driven, Future-focused, Problem-solver.</li>
                            <li>Purpose, Greatness & Fulfilment Seeker. </li>
                            <li>Whole, Unique, Creative, Resourceful, Worthy & Capable and with appropriate guidance <br /> can create your own masterpiece & awaken the greatness within.</li>
                        </ul>
                    </div>

                </div>
            </Sections>
            {/* section */}
            <Sections classes=" collaboration watermark extarqoute ">

                <p> Don’t be fooled into creating a successful life based on the metrics set by the society. Have the courage & confidence to define success that resonates with your reason for being. After all, success without fulfilment is non-existential.</p>

                <p>Your greatness lies in your innate ability to thrive in the suffering and to evolve in the midst of unending back-breaking storms, thereby, actualising the authentic and beautiful form of your perfectly imperfect, uniquely gifted & impactful life.</p>

                <p>Know that you are the best sculptor of your life. No matter what, never let someone else own that responsibility for you. As long as you are conscious of this truth, nothing can stop you from expressing your passion and experiencing sheer joy & fulfilment in the process of finding, nurturing and sharing your gifts with the world.
                </p>
            </Sections>

            {/* section */}

            <Sections classes="qoute conGreenish purquote2">
                <div className="quotes">Can you remember who you were, before the world told you who you should be?</div><p className="text-center"><b>  - Charles Bukowski
                </b></p>
            </Sections>
            {/* section */}

            <Sections classes="conLight WhyMe">
                <h2>ARE YOU?</h2>
                <img className="areyouready" src={process.env.PUBLIC_URL + "./assets/img/you/areYouReady.jpg"} alt="shubham agarwal" />

                <ul className="list ol">
                    <div className="flex">
                        <div className="flexGrow-1"> <li>Seeking purpose and fulfilment in career and life.</li>
                            <li>Tired of being in uncertainty and doing it all by yourself.</li>
                            <li>Sick of overthinking & procrastination and struggling to see the way forward. </li>
                            <li>Tired of pleasing others & trying to fit in, even at the expense of your own well-being.</li>
                            <li>Feeling you’ve had enough of letting the opinions and expectations of others curb your inner voice.</li>
                            <li>Done prioritizing others over yourself.</li>
                            <li>Willing to gather the awareness and courage to follow your passion and live your purpose, even if it demands of you to go against the norms set by the so-called society. </li>
                            <li>Ready to replace your health-compromising behaviours with health-enhancing habits.</li>
                            <li>Willing to find purpose in your pain and acquire the ability to be comfortable with being uncomfortable.</li>
                            <li>Ready to put in the yards to discover your soul print, meet the hero within and evolve to your authentic, vibrant & elevated version.</li>
                            <li>Having Impostor Syndrome & burnout and want to get past it. </li>
                            <li>Ready to do the inner work and regain the power to liberate yourself from the shackles of your toxic environmental conditioning and psychological barriers such as ego, unserving fears, insecurities, unwarranted anxieties, limiting beliefs, self-doubt & poor habits.</li>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + "./assets/img/you/myPick22.jpg"} alt="shubham agarwal" />
                            <img src={process.env.PUBLIC_URL + "./assets/img/you/magic.jpg"} alt="shubham agarwal" />
                        </div>
                    </div>
                    {/* magic */}
                    <div className="flex" >
                        <div className="flexGrow-1 pdr">

                            <li>Willing to diffuse the internal conflict and to get over decision fatigue. </li>
                            <li>Determined to acquire the freedom to fully express your authentic self and be free from the need to lie to others and more importantly to self.</li>
                            <li>Seeking meaningful & uplifting associations.</li>
                            <li>Looking to strengthen your current relationship.</li>
                            <li>Ready to create opportunities to turn your pain into power.</li>
                            <li>Seeking clarity on thoughts, goals, values, purpose, options & the way forward.</li>
                            <li>Willing to regain self-esteem, self-belief, self-love, motivation, focus & mental peace.</li>
                            <li>Looking for a guide to help minimize the possibility of failure.</li>
                            <li>Seeking support to help you find your answers, test your limits and achieve your goals quicker.</li>

                            <li>Seeking to gain a fresh perspective of things and diffuse overwhelming feelings.</li>

                            <li>Looking for someone professional & non-judgemental to share your thoughts & deep-rooted emotions with, to feel understood & supported, and to feel valued & empowered, without risking embarrassment.</li>

                            <li>Looking to get the most out of your invested time, money & energy.</li>
                            <li>Committed to meet the fulfilling version of yourself and thrive in professional, social & personal life.</li>
                        </div>           
                        <div className="img50-50"><img src={process.env.PUBLIC_URL + "./assets/img/you/success.jpg"} alt="shubham agarwal" />
                        <img src={process.env.PUBLIC_URL + "./assets/img/you/commit.jpg"} alt="shubham agarwal" />
                        </div>
                    </div>
                    </ul>
               
                    <p><b>If this resonates with you, then you are in the right place. Let’s talk and explore if working together is a good fit.</b></p>
                            <p><b>You don’t need to see the whole staircase right now, you just need to focus on the very next step. Take a step forward and help me help you get on your remarkable journey of becoming your best self.   </b></p>
                            <div className="text-center"><Link className="link" to="/">BOOK YOUR FREE COACHING CALL</Link></div>
               
            </Sections>

            <Sections classes="conLight conGreenish WhyMe">
                <h2>I URGE YOU TO ASK YOURSELF</h2>
                <ul className="list ol">
                    <div className="flex" >
                        <div className="flexGrow-1 pdr">
                            <li>What lights my soul on fire?</li>
                            <li>What makes me feel alive?</li>
                            <li>What inspires me to wake up every day?</li>
                            <li>What do I have that inspires others?</li>
                            <li>What is my soul power?</li>
                            <li>What’s my untethered soul destined for?</li>
                            <li>What is my greatest gift that the world can benefit from?</li>
                            <li>What resonates with my being the most? Why?</li>
                            <li>Who am I, excluding all relational and professional demand, roles or titles?</li>
                            <li>Who am I when no one is watching me?</li>
                        </div>
                        <div><img src={process.env.PUBLIC_URL + "./assets/img/you/self.jpg"} alt="shubham agarwal" /></div>
                    </div>

                    <li>Who do I deserve to be?</li>
                    <li>How can I be more of who I am?</li>
                    <li>What is it costing me right now by not achieving my goal?</li>
                    <li>How is my present state of being getting me closer to/ away from who I am born to be?</li>
                    <li>What is my biggest fear?</li>
                    <li>What is the limiting belief I am carrying behind that fear? What is the source of that belief?</li>
                    <li>What am I insecure about? Where is this insecurity stemming from?</li>
                    <li>How being vulnerable is going to serve me?</li>
                    <li>What do I tell myself when I feel out of alignment with my element?</li>
                    <li>How often do I schedule an appointment with myself for expert advice?</li>
                    <li>What about me I am proud of?</li>
                    <li>What do I have that no one can take away from me ever?</li>
                    <li>What do I love doing the most?</li>
                    <li>What is that one thing I can devote myself to everyday for the rest of my life?</li>
                    <li>When was the last time I had an immersive experience of something that made me lose track of time?</li>
                    <li>What is it I get so deeply involved in when hours seem like minutes?</li>
                    <li>How much is it worth achieving my goal?</li>
                    <li>What is it I would be doing if I knew I cannot fail?</li>
                    <li>How is my perceived worst past experience, the best thing that has ever happened to me?</li>
                    <li>What is my true worth?</li>
                    <li>What am I capable of achieving?</li>
                    <li>How much do I love myself?</li>
                    <li>What do I not want to regret when I am taking my last few breaths?</li>
                    <li>Who do I want to be remembered as when I am gone?</li>
                </ul>
                <p ><b>If you have read this far, there is definitely something remarkably beautiful within you that can’t wait to come to life.</b></p>
                <p><b>Why wait?  Jump on a call with me & unleash the best in you.</b></p>
                <div className="text-center">
                    <Link to="/" className="link">BOOK YOUR COMPLIMENTARY DISCOVERY CALL</Link>
                    <p className="text-center"> <b>OR</b></p>
                    <Link to="/" className="link">VIEW MY COACHING PROGRAM
                    </Link>
                </div>
            </Sections>

            <Sections classes="conLight collaboration">
                <div className="flex aic">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./assets/img/you/myPick23.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl gbi">
                        <p>Stop being the prisoner of someone else’s opinions and expectations. Start listening to your own inner voice.</p>

                        <p>Sometimes people, especially the ones we love and care about, our friends & family, may not see what we can. And it’s okay. But they will when the dust is finally going to settle down and then it’s going to be inevitably beautiful. You are the only true expert of your life. Own this truth.</p>

                        <p>The most important meeting is the one you have with yourself. Schedule an appointment with yourself at least once a day because you need expert advice.</p>

                        <p>Remember, you are the product of your thoughts. Your self-talk can either be your biggest enemy or your best friend. The funny thing is that it is for you to decide.</p>

                        <p>When we start listening to our soul that whispers, "I am tired of living someone else’s life. It’s high time that you pay attention to me and start taking 100% responsibility for your life", we acquire something very powerful, which is the ability to be comfortable with being uncomfortable and to alchemize our pain into power. That's when we discover our soul print, meet the hero within, become our greater-evolved self & craft an authentic, vibrant & a fulfilling life.</p>



                    </div>
                </div>
            </Sections>

        </>
    )
}

export default You
