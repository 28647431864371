import Header from '../src/assets/components/Header';
import Footer from '../src/assets/components/Footer';
import Home from '../src/assets/pages/Home';
import { Switch, Route} from "react-router-dom"; 
import ScrollToTop from "./assets/components/ScrollToTop";
import CoachingProgram from './assets/pages/CoachingProgram';
import Me from './assets/pages/Me';
import You from './assets/pages/You';
import AboutUs from './assets/pages/AboutUs';
import Contact from './assets/pages/Contact';
import GroupCoachingForOrganizations from './assets/pages/GroupCoachingForOrganizations';
import CareerCoaching from './assets/pages/CareerCoaching';
import LifeCoaching from './assets/pages/LifeCoaching';
import Learn from './assets/pages/Learn';
import CoachingDisclaimer from './assets/pages/learn/CoachingDisclaimer';
import GlobalCodeEthics from './assets/pages/learn/GlobalCodeEthics';
import LifeCoachingUncovered from './assets/pages/learn/LifeCoachingUncovered';
import Testimonials from './assets/pages/Testimonials';

function App() {
  return (
<>
<Header/>
<ScrollToTop/>
<Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/about-us" component={AboutUs}/>
        <Route exact path="/you" component={You}/>
        <Route exact path="/1:1-Coaching-Program" component={CoachingProgram}/>
        <Route exact path="/me" component={Me}/>
        <Route exact path="/group-coaching-for-organizations" component={GroupCoachingForOrganizations}/>
        <Route exact path="/career-coaching" component={CareerCoaching}/>
        <Route exact path="/life-coaching" component={LifeCoaching}/>
        <Route exact path="/learn" component={Learn}/>
        <Route exact path="/learn/coaching-disclaimer" component={CoachingDisclaimer}/>
        <Route exact path="/learn/Global-Code-of-Ethics" component={GlobalCodeEthics}/>
        <Route exact path="/learn/Life-Coaching-Uncovered" component={LifeCoachingUncovered}/>
        <Route exact path="/testimonials" component={Testimonials}/>
        <Route exact path="/contact" component={Contact}/>
      </Switch>
    <Footer/>
</>
  );
}

export default App;
