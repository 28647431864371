import { Link } from "react-router-dom"
import Sections from "../Sections"


const Ready = () => {
    return (
        <Sections classes="conGreenish">
            <div className="wtBg">
            <h2>ARE YOU WILLING & READY TO?</h2>
    <div className="flex ready">
        <div className="flexGrow-1">
                    <ul className="list">
                        <li>Rewrite Your Story And Take 100% Ownership Of Your Uniquely Gifted Life.</li>
                        <li>Transcend Your Fears & Limiting Beliefs.</li>
                        <li>Deconstruct Your Pain And Alchemize It Into Your Power.</li>
                        <li>Turn Your Dreams Into Reality.</li>
                        <li>Take The Leap Towards Crafting The Career, Business, Health & Life You Were Born To Have.</li>
                        <li>Challenge Yourself And Get To A Whole New Level In Your Professional & Personal Life.</li>
                        <li>Discover What You Are Capable Of Achieving & Unlock Your True Potential.</li>
                        <li>Express Your Passion, Live Your Purpose And Become Your Best Authentic Self.</li>
                        <li>Get On Your Heroic Journey Of Self-Discovery & Transformation.</li>
                        <li>Commit To Adopting Empowering Habits, Taking Consistent Action & Pushing Past Your Comfort Zone.</li>
                        <li>Put In The Yards To Actualise An Authentic, Vibrant, Joyful And Fulfilling Life.</li>
                        <li>Rediscover The Person You Were Always Meant To Be.</li>
                    </ul>
        </div>
        <div>
        <img src={process.env.PUBLIC_URL + "./assets/img/home-page/are-you-willing-ready-to.jpg"} alt="shubham agarwal"/>
        </div>
    </div>
    <div className="flex ready">
        <div className="flexGrow-1">
          <p>If yes, then I’m glad you are here.</p>
          <p>Board my coaching rocket and propel yourself to greatness!</p>
          <div className="text-center">
             <Link className="link" to="/">LET’S TALK!</Link>
          </div>
        </div>
        <div>
        <img src={process.env.PUBLIC_URL + "./assets/img/home-page/rocket.jpg"} alt="shubham agarwal"/>
        </div>
    </div>
    </div></Sections>
    )
}

export default Ready
