import { Link } from "react-router-dom"
import Sections from "../Sections"

const Purpose = () => {
    return (
        <>
       <Sections classes="qoute purquopte">
                <div className="quotes">You are born with everything in you that you would ever need to be. The treasure you have been seeking elsewhere lies right within you. By being mindful of this truth, you can regain the consciousness of your inherent limitless potential.
                <br/>
                <br/>
                In the world full of people telling us what to do & what not to do, who to be & who not to be, we need coaches & community more than ever to believe in us, accept us, appreciate us, give us the space to explore within & evolve, provide the freedom to fall before we rise so we can bring out the best in us.
                </div>
    </Sections>
<Sections classes="nonepd">
<p><b>I know what it is like to be Directionless, Confused, Insecure, Depressed and Emotionally Drained.</b> Let me hold your hand in your quest of expressing your passion, finding your purpose and acquiring the clarity & confidence to face your challenges and achieve your meaningful goals. If you allow me to do so, I’ll take you on an adventure to your very own heroic journey of self-discovery & transformation, so you can give yourself the reward of being <b>Alive & Fulfilled.</b> </p>
</Sections>

<Sections classes="qoute purquote2 mobreqest">
                <div className="quotes">Pain Plus Reflection Equals Progress.</div>
                <p className="text-center"><b>- Ray Dalio</b></p>
    </Sections>
    <Sections classes="nonepd">
<p><b>By having raw, open-ended, confidential & insightful conversation and through a personalized coaching program free of judgement & biases,</b>  I’ll not only help you see the deep-rooted purpose in your pain but will also empower you to meet your greater evolved self and radiate your inner force, so that you can create and enjoy a healthy, prosperous & joyful life.  </p>
<p>What I mean is that <b>through deep listening and by asking some intuitive, thought-provoking & soul-touching questions,</b> I’ll help you take the inward journey to your deepest realm where lies your soul power and get you past the door where lies your real answers. </p>

<p><b>Basically, I’ll provide you the right kind of MIRROR to reflect authentically so you will reach a place within you where you will see THE SOLUTION.
</b></p>
<div className="flex">
<div className="flexGrow-1 pdr">
    <p><b>I have found my purpose, let me help you find yours!</b></p>  
    <p>If you are ready to challenge yourself to commit to your growth & well-being, to exit the matrix and to set out on a remarkable journey of creating your masterpiece, we should have a conversation and explore if working together is a good fit.</p>
    <p><b>Whatever got you here and kept you on my website, give it a chance. Take a leap of faith and help me help you ignite the spark within you.</b></p>
    <p><b>If not now, when?</b></p>
    <div className="text-center">
<Link className="link" to="/">BOOK YOUR FREE COACHING CALL</Link>

    </div>
</div>
<div>
<img src={process.env.PUBLIC_URL + "./assets/img/home-page/purpose.jpg"} alt="shubham agarwal"/>
</div>
</div>
</Sections>
<Sections classes="qoute purquote2">
                <div className="quotes">There is nothing in a Caterpillar that tells you it's going to be a Butterfly. </div>
                <p className="text-center"><b>- R. Buckminster Fuller</b></p>
    </Sections>
        </>
    )
}

export default Purpose
