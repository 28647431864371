import { Link } from "react-router-dom"
import Sections from "../Sections"


const Collaboration = () => {
    return (
        <Sections classes="conLight collaboration">
        <div className="flex col-sm-reverse">
            <div className="flexGrow-1 pdr">
                <p><b>You and I will have an equal-footing adult-to-adult collaboration</b> in which I am going to take you from functional to a phenomenal state by asking open-ended reflective questions and by using necessary tools, techniques & strategies.</p>
                <p><b>Through a solution-focused and creative partnership</b> laid on the foundation of Ethics, Trust and Transparency, together we will set out on a fulfilling journey of determining your starting point, the end goal & the way forward and generate insights leaving you with an enriching experience. In this collaborative effort, I am not only going to be your LIGHTHOUSE helping you stay on course towards creating your masterpiece but also your FREEDOM PARTNER supporting you liberate from the shackles of your toxic environmental conditioning and psychological barriers.</p>
              <p>Welcome to my <b>collaborative and result-oriented Career & Life Coaching Program,</b> tailored to your specific needs and in alignment with the international coaching standards.</p>
              <Link className="link" to="/">KNOW MORE</Link>
            </div>
            <div>
                <img src={process.env.PUBLIC_URL + "./assets/img/home-page/handshake.jpg"} alt="shubham agarwal" />
            </div>
        </div>

    </Sections>
    )
}

export default Collaboration
