import { Link } from "react-router-dom"

const Plan = (props) => {
    return (
        <div className="col-3 col-md-6 co-sm-6 col-xs-12 aftr">
        <div className="plans">
            <div className="padding-20">
            <h4>{props.heading}</h4>
            {props.children}
            </div>
            <div>
            <Link className="link" to={props.goTo}>SCHEDULE COMPLIMENTARY DISCOVERY SESSION</Link>
            </div>
        </div>
    </div>
    )
}

export default Plan
