import Sections from "../Sections"
import { Link } from "react-router-dom"

const MypickFour = () => {
    return (
        <Sections classes="conLight">
        <div className="flex aic mypick4">
            <div>
            <img src={process.env.PUBLIC_URL + "./assets/img/home-page/myPick4.jpg"} alt="shubham agarwal"/>
            </div>
            <div className="flexGrow-1 pdl">
                <p><b>
                There is only one true master of your life. And that’s YOU. Are you ready to OWN THAT RESPONSIBILITY?
                    </b></p>
                <div className="quotes">When the regret of staying in your comfort zone outweighs the fear of stepping out of it, it's time to take the leap to your growth zone.</div>
<p><b>Take the leap and schedule your no-obligatory 90 minute complimentary discovery call with me to get the taste of my coaching, a thorough understanding of my coaching program and to find out whether we are the right fit for each other. </b></p>
<div className="text-center"><Link to="/" className="link">BOOK YOUR FREE DISCOVERY COACHING CALL</Link></div>

            </div>
        </div>
    </Sections>
    )
}

export default MypickFour
