import { Link } from "react-router-dom"
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"


const Contact = () => {
    return (
        <>
            <PageBanner pagename="Conatct Us" />

            {/* section */}
            <Sections classes="WhyMe white-text whyMe watermark contact">
                <div className="flex aic">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./assets/img/contact/myPick19.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl">
                        <h2>Let’s Connect! I’d love to hear from you.</h2>
                        <p><Link className="dbg" to="mailto:hello@shubhamagarwal.me">hello@shubhamagarwal.me</Link></p>
                        <p>Or</p>

                        <form action="/"  >
                            <div className="flex aic drkform">
                                <div className="label"><label htmlFor="name">Name</label></div>
                                <div className="flexGrow-1 input">
                                    <input type="text" required id="name" name="name" placeholder="Enter Your Name" />
                                </div>
                            </div>
                            <div className="flex aic drkform">
                                <div className="label"><label htmlFor="email">Email</label></div>
                                <div className="flexGrow-1 input">
                                    <input type="email" id="email" name="email" required placeholder="Enter Your Email Id " />
                                </div>
                            </div>
                            <div className="flex drkform">
                                <div className="label"><label htmlFor="message">Message</label></div>
                                <div className="flexGrow-1 input">
                                    <textarea id="message" name="message" placeholder="Enter Your Message" rows={4} cols={50} />
                                </div>
                            </div>
                            <input type="submit" className="link" defaultValue="Submit" />

                        </form>

                    </div>
                </div>
            </Sections>
            {/* section */}
            <Sections classes="conLight WhyMe">
                <div className="flex aic">
                    <div className="contactimg"><img src={process.env.PUBLIC_URL + "./assets/img/contact/myPick20.jpg"} alt="shubham agarwal" /></div>
                    <div className="flexGrow-1 pdl pdr">
                        <h2 className="text-center">
                            GRAB YOUR FREE LIFE ASSESSMENT TICKET
                            <p className="text-center"><i> …And get crystal clear on your values, priorities, purpose & vision!</i></p>
                        </h2>

                    </div>
                    <div className="contactimg"><img src={process.env.PUBLIC_URL + "./assets/img/you/youare.jpg"} alt="shubham agarwal" /></div>
                </div>
                <p>My free tool guide is meant to help you take a bird’s-eye view of all the areas of your life. With this, not only you will become aware of the time & energy needed to be spent on those respective areas, but also form a strategic approach for getting closer to your desired outcome at your own pace and in a consistent progressive fashion.</p>
                <p>Just enter your name and email to get instant access!</p>
                <form action="/" className="form"  >
                    <div className="flex aic drkform lgtform">
                        <div className="label"><label htmlFor="name2">Name</label></div>
                        <div className="flexGrow-1 input">
                            <input type="text" required id="name2" name="name2" placeholder="Enter Your Name" />
                        </div>
                    </div>
                    <div className="flex aic drkform lgtform">
                        <div className="label"><label htmlFor="email2">Email</label></div>
                        <div className="flexGrow-1 input">
                            <input type="email" id="email2" name="email2" required placeholder="Enter Your Email Id " />
                        </div>
                    </div>

                  <div className="text-center">  <input type="submit" className="link" defaultValue="GET INSTANT ACCESS NOW" value="GET INSTANT ACCESS NOW" /> </div>
                </form>
 
            </Sections>
            <Sections classes="conLight conGreenish WhyMe">
            <div className="text-center">
    <Link to="/" className="link">BOOK YOUR COMPLIMENTARY DISCOVERY CALL</Link>
  <p className="text-center"> <b>OR</b></p>
   <Link to="/" className="link">VIEW MY COACHING PROGRAM
</Link>
</div>
            </Sections>
        </>
    )
}

export default Contact
