import { Link } from "react-router-dom"
import Sections from "../Sections"

const Committed = () => {
    return (
        <Sections classes="conDark committed">
        <div className="flex aic">
            <div className="flexGrow-1 pdr">
                <h2>DOES THIS SOUND LIKE YOU?</h2>
                   <p> Driven but Directionless.</p>
                   <p> Aspirational but Confused.</p>
                   <p> Resourceful but Constrained.</p>
                   <p> Ingenious but Anxious.</p>
                   <p> Worthy but Doubtful.</p>
                   <p> Ambitious but Exhausted.</p>
                   <p> Whole but Lost.</p>
                   <p> Capable but Unfulfilled.</p>
                
                   <Link to="/" className="link">KNOW MORE</Link>
            </div>
            <div>
                <img src={process.env.PUBLIC_URL + "./assets/img/home-page/committed.jpg"} alt="shubham agarwal" />
            </div>
        </div>

    </Sections>
    )
}

export default Committed
