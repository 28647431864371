import Plan from "../components/CoachingProgam/Plan"
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"
import { Link } from "react-router-dom"

const CoachingProgram = () => {
    return (
        <>
            <PageBanner pagename="1:1 COACHING PROGRAM" />
            {/* Sections */}
            <Sections classes="qoute conGreenish">
                <div className="flex">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./assets/img/coachingProgram/myPick18.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl coachingProgramBP">
                        <p><b>What is it costing you right now by not achieving your goal? </b></p>
                        <p><b>How much is it worth to you meeting your desired outcome? </b></p>
                    </div>
                </div>
            </Sections>
            {/* Sections */}
            <Sections classes="qoute socrates">
                <div className="quotes">
                    I am the master of my fate, I am the captain of my soul.
                </div>
                <p class="text-center"><b>- William Ernest Henley</b></p>
            </Sections>
            <Sections classes="WhyMe watermark start-in">
                <h2>START INVESTING IN YOURSELF WITH MY SIGNATURE COACHING PROGRAM</h2>
                <p>I’m glad to have you here. Welcome to my one-on-one Solution-Focused Career & Life Coaching Program, tailored to your specific needs, and in alignment with the international coaching standards. </p>
                <p>First of all, I commend you for showing the willingness and readiness to invest in your Growth and Success. In this equal footing creative collaboration, I am going to take you from being FUNCTIONAL to PHENOMENAL by being your LIGHTHOUSE, your GUIDE and your SUPPORT PARTNER. Moreover, I’ll empower you to set out on your remarkable journey of SELF-DISCOVERY & TRANSFORMATION, so you can give yourself the reward of feeling ALIVE & FULFILLED. </p>
                <p>I know what it is like to be Directionless, Confused, Anxious, Insecure, and Emotionally Drained. Let me hold your hand in your quest of Expressing Your Passion, Living With Purpose and Acquiring The Clarity & Confidence to face your Challenges and achieve your Meaningful Goals.  </p>
            </Sections>
            {/* Sections */}
            <Sections classes="collaboration">
                <div className="flex aic">
                    <div className="flexGrow-1 pdr">
                        <p><b>My program is designed to not only help you assess your present situation & problems and set your worthwhile goal, but also support you form Habits, Strategies & Plan Of Action to remove roadblocks and create opportunities to push past your Comfort Zone and build the Confidence & Momentum to start accelerating towards your desired outcome. </b> </p>
                        <p><b>So, if you are ready to Invest in Your Growth & Fulfilment and to have a Personalized Experience!</b></p>
                    </div>
                    <div><img src={process.env.PUBLIC_URL + "./assets/img/coachingProgram/investing-in-yourself.jpg"} alt="shubham agarwal" /></div>
                </div>
            </Sections>
            {/* Sections */}

            <Sections classes="collaboration watermark coachin-plans">
                <h2>HERE ARE YOUR TRANSFORMATIONAL COACHING PLANS </h2>
                <p>Based on your needs, you can select the most preferred plan from the ones listed below.  </p>
                <div className="row multiple planROw">

                    {/* Plan */}
                    <Plan heading="1M DISCOVERY PLAN" goTo="/">
                        <p>CONNECT & DISCOVER </p>
                        <ul>
                            <li>Build Clarity & Confidence </li>
                            <li>Generate Insights</li>
                            <li>Broaden Perspective</li>
                        </ul>
                        <p>1 Month Program of 4 Sessions </p>
                        <p>Weekly 60 min coaching sessions </p>
                    </Plan>

                    {/* Plan */}
                    <Plan heading=" 3M GROWTH PLAN " goTo="/">
                        <p>TRANSCEND & THRIVE</p>
                        <ul>
                            <li>Take Action </li>
                            <li>Step Outside Your Comfort Zone</li>
                            <li>Maximize Potential</li>
                        </ul>
                        <p>3 Month Program of 12 Sessions </p>
                        <p>Weekly 60 min coaching sessions  </p>
                    </Plan>

                    {/* Plan */}
                    <Plan heading="6M FULFILMENT PLAN" goTo="/">
                        <span>Best Value</span>
                        <p>DEEP DIVE</p>

                        <ul>
                            <li>Feel Alive </li>
                            <li>Taste Greatness</li>
                            <li>Experience Fulfilment</li>
                        </ul>
                        <p>6 Month Program of 24 Sessions </p>
                        <p>Weekly 60 min coaching sessions</p>
                    </Plan>

                    {/* Plan */}
                    <Plan heading="MY CUSTOMIZED COACHING PLAN" goTo="/">
                        <p>Let’s Customize Your Coaching Program </p>
                    </Plan>
                </div>

                <h2>Important Note: </h2>
                <ul className="list italic">
                    <li>Our very first session will be a free of charge 90 min interaction. Each of the following sessions will be an hour long.</li>
                    <li>Instalment Payment Option Available</li>
                    <li>Package Price will be discussed in the Discovery Call</li>
                    <li>Online sessions via Zoom.</li>
                    <li>Session recording will be provided, if opted for.</li>
                    <li>Support between sessions available.</li>
                    <li>Coaching Agreement will be executed after having your consent on the coaching plan.</li>
                </ul>
                <p><i>If you are still unsure, do not worry. Click <Link to="/"> HERE </Link> to connect with me and explore in detail.</i></p>
                <div className="hasTag">
                    <p><i>#LIFECOACHING #CAREERCOACHING #PURPOSECOACHING #FULFILMENTCOACHING #WELLNESSCOACHING #CONFIDENCECOACHING #MINDSETCOACHING #PERSPECTIVECOACHING #SUCCESSCOACHING #THRIVECOACHING #CONSCIOUSNESSCOACHING #STRESSCOACHING #ANXIETYCOACHING #GUILTCOACHING #GRIEFCOACHING #AGGRESSIONCOACHING #PERFORMANCECOACHING #EMOTIONALCOACHING #BEHAVIORALCOACHING #TRANSFORMATIONALCOACHING #HABITSCOACHING  #LEADERSHIPCOACHING #BUSINESSCOACHING #ENTREPRENEURSHIPCOACHING #EXECUTIVECOACHING #TEAMCOACHING #RELATIONSHIPCOACHING #SPIRITUALCOACHING</i></p></div>
            </Sections>

        </>
    )
}
export default CoachingProgram
