import { Link } from "react-router-dom"
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"
const myPick172 = process.env.PUBLIC_URL+"/assets/img/services/myPick17-2.jpg";
const career = process.env.PUBLIC_URL+"/assets/img/services/career.jpg"; 

const CareerCoaching = () => {
    return (
        <>
            <PageBanner pagename="CAREER COACHING (for Individuals)" />

            {/* section */}
            <Sections classes="qoute purquote2">
                <div className="quotes">It costs you nothing to believe in yourself but it will cost you everything if you don’t</div>
                <p className="text-center"><b> – Joel Brown</b></p>
            </Sections>
            {/* section */}
            <Sections classes="WhyMe conGreenish">
                <div className="flex aic">
                    <div className="flexGrow-1 pdr">
                        <div className="gbi">
                            <p>Boredom, Stress, Anger, Confusion, Frustration, Depression, Insecurity, Unemployment, Toxic Associations, Self-doubt, Procrastination and Stagnation are PAINFUL, so does the work needed to break these barriers. One leads to REGRET while the other leads to FULFILMENT. It’s yours to decide which pain you want.</p>
                        </div>
                        <img className="mobimg" src={myPick172} alt="shubham agarwal" />
                        <p>It’s time that you:</p>
                        <ul className="list ol">
                            <li> Wake up feeling excited to start your day.</li>
                            <li> Feel inspired and belong when at work.</li>
                            <li> Be done with your work with the fulfilment of having contributed your best, and</li>
                            <li> Hit the bed with your soul at peace. </li>
                        </ul> 
                        <img className="mobimg" src={career} alt="shubham agarwal" />
                        <p>Career Coaching is as much for a Student, a Recent Graduate or a Novice Professional as it is for a Mid-Career/ Highly Experienced Professional, an Entrepreneur or an Executive.</p>
                    </div> 
                    <div className="deskimg">
                        <img src={myPick172} alt="shubham agarwal" />
                        <img src={career} alt="shubham agarwal" />
                    </div>
                </div>
            </Sections>
            {/* section */}
            <Sections classes="WhyMe contWhite">
                <h2>WHY?</h2>
           
                <ul className="list ">
                   <li> Career Clarity, Career Transition, Career Alignment, Career Progression & Career Fulfilment</li>
                   <li> Trust & Camaraderie</li>
                   <li> Mindfulness & Heightened Emotional Intelligence</li>
                   <li> Personal & Collective Well-Being</li>
                   <li> Interpersonal Skills & Leadership Development</li>
                   <li> Sense of Belongingness & Performance Enhancement</li>
                   <li> Workplace Engagement</li>
                   <li> Fears, Limiting Beliefs & Confidence Issues</li>
                   <li> Workplace Conflict & Workplace Anxiety</li>
                   <li> Toxic Work Environment</li>
                   <li> Work Life Balance</li>
                   <li> Professional & Business Milestones</li>
                   <li> Unemployment Woes & Pay Raise</li>

                </ul>
            </Sections>
                {/* section */}
            <Sections classes="WhyMe conDark">
                <h2>WHO?</h2>

    <ul className="list ol">
                    <li>Aspiring & Mid-Career Professionals</li>
                    <li>Corporate Professionals & Business Executives</li>
                    <li>Entrepreneurs</li>
                    <li>Students & Recent Graduates</li>
                    <li>Job Seekers</li>
                    <li>Athletes</li>
                    <li>Artists</li>
                    <li>Confidence, Purpose & Fulfilment Seekers</li>

                </ul>
                <p>And who are determined to take charge of their CAREER.</p>
  </Sections>
    {/* section */}
    <Sections classes="conLight conGreenish WhyMe how ">
    <div class="flex aic">
    <div className="flexGrow-1 pdr"><h2>HOW?</h2>
                <ul className="list ol">
                    <li>My Signature Coaching Program </li>
                    <li>Strategies, Tools & Techniques tailored to your needs. </li>
                    <li>Online Session via Zoom </li>
                </ul>
                <p><b>Ready to propel yourself to greatness?</b></p>
                <p><b>Connect with me today & empower yourself to craft a Career filled with Purpose & Fulfilment!  </b></p>
                   <div className="text-center"><Link to="/" className="link">BOOK YOUR COMPLIMENTARY DISCOVERY CALL</Link>
                   <p  className="text-center"><b>Or</b></p>
                   <Link to="/" className="link">VIEW MY COACHING PROGRAM</Link>
                   </div></div>
    <div>
    <img src={process.env.PUBLIC_URL + "./assets/img/services/rocket.jpg"} alt="shubham agarwal" />
    </div>
</div>
                
            </Sections>
            </>
    )
}

export default CareerCoaching
