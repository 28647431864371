
import { Link } from "react-router-dom"
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"


const GroupCoachingForOrganizations = () => {
    return (
        <>
            <PageBanner pagename="GROUP (TEAM) COACHING & TRAINING FOR ORGANIZATIONS" />

            {/* section */}
            <Sections classes="qoute purquote2">
                <div className="quotes">If you want to go fast, go alone. If you want to go far, go together.</div>
                <p className="text-center"><b>– African Proverb</b></p>
            </Sections>
            {/* section */}
            <Sections classes="WhyMe conGreenish">
                <div className="flex aic">
                    <div>
                    <img src={process.env.PUBLIC_URL + "./assets/img/services/myPick18.jpg"} alt="shubham agarwal" />
                    </div>
                    <div className="flexGrow-1 pdl">
                        <p><b>ARE YOU AN ORGANIZATION OR A TRIBE LOOKING TO ENHANCE INDIVIDUAL & COLLECTIVE WELL-BEING, GROWTH, PERFORMANCE & FULFILMENT LEVELS?</b></p>
                        <p><b>IF SO, WELCOME TO MY AWARENESS-FOCUSED AND GROWTH-ORIENTED GROUP COACHING PROGRAM MEANT TO BRING OUT THE BEST IN YOUR TEAM.</b></p>
                        <p>Group or Team Coaching is focused on improving Personal, Professional & Social Life in a Peer-to-Peer Learning & Sharing Model.</p>
                    </div>
                </div>
            </Sections>
            {/* section */}
            <Sections classes="WhyMe watermark what">
                <h2>WHAT?</h2>
                <p>My group coaching program is focused on amplifying Confidence, Growth & Fulfilment levels through a series of deep interactions with the group members.</p>
                <p>The coaching sessions are meant to help the participants with :   </p>

                <ul className="list">
                    <li>Improving Personal & Collective Well-Being</li>
                    <li>Developing Trust & Camaraderie</li>
                    <li>Building Leadership Effectiveness</li>
                    <li>Obtaining Clarity of Passion, Purpose & Direction</li>
                    <li>Heightening levels of Awareness, Confidence, Focus & Energy</li>
                    <li>Broadening Perspective</li>
                    <li>Facilitating an atmosphere of Connection, Belongingness & Inclusivity</li>
                    <li>Gaining Interpersonal Skills</li>
                    <li>Alleviating Stress & Anxiety Levels</li>
                    <li>Replacing Unserving Habits with Success Habits</li>
                    <li>Moving from Comfort Zone to Discomfort Zone (a.k.a. The Growth Zone) </li>
                    <li>Breaking Through Self-Doubt & Insecurities</li>
                    <li>Debunking Unwarranted Fears & Limiting Beliefs</li>
                    <li>Up-levelling Self-Belief & Self-Esteem</li>
                    <li>Elevating Self-Love & Self-Worth</li>
                    <li>Climbing on the ladder of Consciousness Continuum</li>
                    <li>A Shift from Victim to Creator Mindset</li>
                    <li>Adding Meaning & Fulfilment to Professional & Personal Life</li>
                    <li>Increased drive & motivation for Conscious Planning, Execution & Reflection</li>
                    <li>Mindfulness & Heightened Emotional Intelligence</li>
                    <li>Enhanced Performance & Productivity</li>
                    <li>Forming Solution-Focused & Abundance Mindset</li>
                    <li>Reaching Personal & Organizational Milestones</li>
                    <li>Leveraging gains from increased Accountability & Discipline  </li>
                    <li>Letting go of Guilt & Shame</li>
                    <li>Triumphing over past Trauma(s) </li>
                    <li>Conflict Resolution</li>
                    <li>Improved Work Life Balance</li>
                    <li>Health & Wellness</li>
                    <li>Crafting Uplifting Associations & Meaningful Relationships</li>
                    <li>Letting go of the tendency to Over-Analyse & Procrastinate</li>
                </ul>
            </Sections>
                {/* section */}
            <Sections classes="WhyMe white-text watermark why">
                <h2>WHY?</h2>

    <ul className="list ol">
                    <li>Higher Energy, Shared Wisdom, More Affordable.</li>
                    <li>Group members have same or different Goals but similar Interests, Values & Beliefs.</li>
                    <li>Each person will have the opportunity to give & receive help and support in a Peer to Peer Sharing Setup.</li>
                    <li>Everyone wins by developing Support & Trust within the group.</li>
                    <li>Peer-To-Peer Learning and co-creating a Growth Mindset.</li>
                    <li>Foster Collaboration and Break Down The Silo Mentality.</li>
                    <li>Get to be a part of something Bigger than Yourself.</li>
                    <li>Form an amazing Bond with a Tribe of People with similar Values & Beliefs.</li>
                    <li>Have access to different Approaches & Perspectives to Problems & Pain Points.</li>
                </ul>
  </Sections>
    {/* section */}
            <Sections classes="WhyMe watermark how">
                <h2>HOW?</h2>
                <ul className="list">
                    <li>My Signature 4-24 Weeks Coaching Program.</li>
                    <li>A 90 minute introductory free coaching session with no obligations.</li>
                    <li>Organizations can get a Customized Program catering to their needs.</li>
                    <li>Online & Offline Group Coaching Session and Workshop comprise of atleast 4 participating members (coachees).</li>
                    <li>A new topic around Personal Growth, Well-Being, Performance Enhancement & Fulfilment touching various aspects of work & life will be introduced in each session.</li>
                </ul>
                <p><b>Want to experience an Amazing Camaraderie and Invest in your Growth, Well-Being & Fulfilment?</b></p>
                <div className="text-center"><Link to="/" className="link">BOOK YOUR COMPLIMENTARY DISCOVERY CALL</Link></div>
                <p className="mtop15">
                    <b> Please feel free to enquire via <a className="textlink" href="mailto:hello@shubhamagarwal.me">hello@shubhamagarwal.me</a>
                    </b>
                </p>
            </Sections>
            

        </>
    )
}

export default GroupCoachingForOrganizations
