import { Link } from "react-router-dom"
import PageBanner from "../components/PageBanner"
import Sections from "../components/Sections"
const myPick17 = process.env.PUBLIC_URL+"/assets/img/services/myPick17.jpg";
const coaching = process.env.PUBLIC_URL+"/assets/img/services/coaching.jpg"; 

const LifeCoaching= () => {
    return (
        <>
            <PageBanner pagename="LIFE COACHING (for Individuals)" />

            {/* section */}
            <Sections classes="qoute purquote2">
                <div className="quotes">I learned that every mortal will taste death, but only some will taste life</div>
                <p className="text-center"><b> – Rumi</b></p>
            </Sections>
            {/* section */}
            <Sections classes="WhyMe conGreenish">
                <div className="flex aic">
                    <div className="flexGrow-1 pdr">
                        <div className="gbi">
                            <p>You are both the marble & the sculptor. And the sculptor cannot produce the work of beauty without chipping away the unneeded parts of the stone blocks.</p>
                            <p>It’s time you retake the charge and start crafting a healthy, happy & purposeful life.</p>
                        </div>
                        <img className="mobimg" src={myPick17} alt="shubham agarwal" />
                        <p>Get ready to delve within & put in the work in order to: </p>
                        <ol className="list ol reab">
                            <li> BREAK years & years of Toxic Environmental Conditioning & Psychological Barriers such as Ego, Biases, Unserving Fears, Insecurities, Unwarranted Anxieties, Self-Doubt, Self-Limiting Beliefs & Negative Habits, and</li>
                            <li> GAIN Self-Awareness, Self-Discipline, Self-Care, Self-Esteem, Self-Love, Self-Worth, Self-Belief, Authenticity, Wholeness, and Empowering Habits to Discover Your Soul Print and Meet The Hero Within. </li>
                        </ol>
                        <img className="mobimg" src={coaching} alt="shubham agarwal" />
                        <p>Life coaching addresses various areas of life, from Health, Career & Personal Growth to Relationships & Spirituality. The positive impact created on one area of clients’ life, ripples not only on the other areas but also benefits other people in their circle.    </p>
                        <p><b>Coaching is more powerful than changing lives; it shows people how to transform their own lives. It has the potential to move past regional and cultural boundaries, impacting society. </b></p>
                    </div>
                    <div className="deskimg">
                        <img src={myPick17} alt="shubham agarwal" />
                        <img src={coaching} alt="shubham agarwal" />
                    </div>
                </div>
            </Sections>
            {/* section */}
            <Sections classes="WhyMe contWhite">
               
                <div class="flex aic">
                    
    <div className="flexGrow-1 pdr">
    <h2>WHY?</h2>
          <ul className="list">
                    <li>Clarity of Purpose</li>
                    <li>Perspective & Mind Shift</li>
                    <li>Health & Wellness</li>
                    <li>Triumphing Over Childhood Trauma</li>
                    <li>Breaking Through Limiting Beliefs, Social & Personal Anxieties</li>
                    <li>No More Fear of Failure, Success, Loneliness, Change, Uncertainty, Rejection, Inadequacy or Being Judged.</li>
                    <li>Re-Gaining Inner Liberation & Personal Freedom</li>
                    <li>Getting Over Years of Pain, Guilt & Shame</li>
                    <li>Turn Self-Doubt to Self-Belief & Self-Esteem.</li>
                    <li>Elevate Self-love & Self-Worth,</li>
                    <li>Replace Unserving Habits with Success Habits.</li>
                    <li>Climb on the ladder of Consciousness Continuum</li>
                    <li>Heightened Clarity, Confidence, Focus & Energy</li>
                    <li>Replace Toxic Associations with Uplifting Ones</li>
                    <li>Add Meaning & Fulfilment to Relationships</li>
                      </ul></div>
    <div>
    <img className="br100" src={process.env.PUBLIC_URL + "./assets/img/you/youare.jpg"} alt="shubham agarwal" />
    </div>
</div>
              
            </Sections>
                {/* section */}
            <Sections classes="WhyMe conDark">
                <h2>WHO?</h2>

    <ul className="list ol">
                   <li> Aspiring & Mid-Career Professionals</li>
                   <li> Corporate Professionals & Business Executives</li>
                   <li> Entrepreneurs</li>
                   <li> Students & Recent Graduates</li>
                   <li> Job Seekers</li>
                   <li> Athletes</li>
                   <li> Artists</li>
                   <li> Homemakers</li>
                   <li> Parent</li>
                   <li> Confidence, Purpose & Fulfilment Seekers</li>


                </ul>
                <p>And who are committed to take charge of their LIFE.</p>
  </Sections>
    {/* section */}
    <Sections classes="conLight conGreenish WhyMe how ">
 <h2>HOW?</h2>
                <ul className="list ol">
                    <li>My Signature Coaching Program </li>
                    <li>Strategies, Tools & Techniques tailored to your needs.  </li>
                    <li>Online Session via Zoom </li>
                </ul>   
                <p><b>Ready to dive deep within? </b></p>
                <p><b>Reach out to me today and take charge of crafting a fulfilling life!  </b></p>
                   <div className="text-center "><Link to="/" className="link">BOOK YOUR COMPLIMENTARY DISCOVERY CALL</Link>
                   <p className="text-center" ><b >Or</b></p>
                   <Link to="/" className="link">VIEW MY COACHING PROGRAM</Link>
                   </div>
                
            </Sections>
            </>
    )
}

export default LifeCoaching
