import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="flex aic">
                    <div className="pdr">
                        <div className="text-center">  <Link href="/" className="social"><i className="i-facebook"></i></Link>
                        <Link href="/" className="social"><i className="i-instagram"></i></Link>
                        <Link href="/" className="social"><i className="i-linkedin"></i></Link></div>
                      
                        <p>Copyright Shubham Agarwal © 2021  </p>
                    </div>
                    <div className="flexGrow-1 text-right"><img src={process.env.PUBLIC_URL + "/assets/img/home-page/jay.jpg"} alt="shubham agarwal" /></div>
                    <div><img src={process.env.PUBLIC_URL + "/assets/img/home-page/footerlogos.jpg"} alt="shubham agarwal" /></div>
                </div>
            </div>
        </footer>
    )
}
export default Footer
