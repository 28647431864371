import { Link } from "react-router-dom"
import Sections from "../Sections"

const Checkout = () => {
    return (
        <Sections classes="conGreenish">
<div className="row aic checkout">
<div className="col-6 col-md-6 col-sm-12">
    <img src={process.env.PUBLIC_URL + "./assets/img/home-page/myPick9.jpg"} alt="shubham agarwal"/>
    </div>
    <div className="col-6 col-md-6 col-sm-12">
    <h2>CHECKOUT…</h2>
    <Link to="/" className="link">TEAM COACHING FOR ORGANIZATIONS</Link>
    <Link to="/" className="link">CAREER COACHING FOR INDIVIDUALS</Link>
    <Link to="/" className="link">LIFE COACHING FOR INDIVIDUALS</Link>
    <Link to="/" className="link">COACHING PROGRAM FOR INDIVIDUALS</Link>
    <Link to="/" className="link">RAVE REVIEWS</Link>
    <Link to="/" className="link">YOUR FREE LIFE ASSESSMENT</Link>
    <Link to="/" className="link">BLOG</Link>
    </div>

</div>

</Sections>
    )
}

export default Checkout
